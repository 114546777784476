import React, { useState, useEffect }  from 'react';
import {Link} from "react-router-dom";
import { useNavigate} from 'react-router-dom';
import { collection, getDocs, } from "firebase/firestore";
import {db} from '../../firebase'
import ReactPlayer from "react-player";
import Navbar from "../inc/Navbar";
import PaginationOld from './PaginationOld';

import ScrollToTop from "react-scroll-to-top";

function Coto() {
    const [ setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

    const [showPerPage, setShowPerPage] = useState(50);
    const [pagination, setPagination] =useState({
        start: 0,
        end: showPerPage,
    });

    const onPaginationChange = (start,  end) =>{
        setPagination({start: start, end: end});
    };
    const [searchTerm, setsearchTerm] = useState("");
    const [coto, setCoto] = useState([])
    const navigate = useNavigate()

    const cotoCollectionRef = collection(db, "coto");
    useEffect(() => {

        const getCoto = async () => {
            const data = await getDocs(cotoCollectionRef);
            setCoto(data.docs.map((doc) => ({...doc.data(), id: doc.id })))
        }

        getCoto()
    }, [])
    return (
        
<>
<Navbar/>
<br/>
<br/>
<br/>
<ScrollToTop smooth top="100" color="dark"/>



<div className="album py-1">
        <div className="container">

            <div className="row">
            <nav aria-label="breadcrumb">
  <ol className="breadcrumb">
  <li className="breadcrumb-item"><Link to="/" href="#">HOME</Link></li>
    <li className="breadcrumb-item"><Link to="/Kajaria" >KAJARIA BEDROOM TILES</Link></li>
    <li className="breadcrumb-item"><Link to="/KajariaKitchenwall" >KAJARIA KITCHEN WALL TILES</Link></li>
    <li className="breadcrumb-item"><Link to="/Johnson" >JOHNSON</Link></li>
    <li className="breadcrumb-item"><Link to="/Orientbell" >ORIENTBELL</Link></li>
    <li className="breadcrumb-item"><Link to="/Jaquar" >JAQUAR</Link></li>
   
    <li className="breadcrumb-item"><Link to="/Somany" >SOMANY</Link></li>
    <li className="breadcrumb-item"><Link to="/Dulux" >DULUX</Link></li>
    <li className="breadcrumb-item"><Link to="/Finolex" >FINOLEX</Link></li>
    <li className="breadcrumb-item active" aria-current="page">COTO PRODUCT</li>
  </ol>
</nav>
</div>
</div>
</div>
<div className="album py-1">
        
            <div className="container">

                <div className="row">
               
                <div className="col-md-6">
                        
                        <div className="card-body">
                        <input type="text" class="form-control" placeholder="Search" name="location" 
                        onChange ={(e) => {
                          setsearchTerm(e.target.value);
                        }}
                        />
                                <div className="d-flex justify-content-between align-items-center">
                                   
                                    
                            

                            </div>
                          
                    
                        </div>

                      
                    </div>
             
                    </div>
                  
                    </div>
                    </div>

<div className="album py-0">
        
        <div className="container">

            <div className="row">
            {coto.filter((val)=>{
      if (setsearchTerm === ""){
        return val;
      } else if (
        val.video.toLowerCase().includes(searchTerm.toLowerCase()) 
    

       
  
      ){
        return val;
      }
    }).slice(pagination.start, pagination.end).map((coto) => { return (
       
            <div className="col-md-4 mt-4">
                        <div className="card mb-4 me-0 border-dark box shadow">
                        <ReactPlayer   width='354px' height='420px' url={coto.video}/>
                         
                             


                         
                              
                                <div className="d-flex justify-content-between align-items-center">
                              
                                        

                                       
                                    
                              
                        
                            </div>
                            </div>
                               
                            </div>
                                )})} 
                            </div>
                            <PaginationOld showPerPage={showPerPage} 
                onPaginationChange={onPaginationChange}
                total={coto.length}
                />
                            </div>
                            </div>
                            <br/>
                            
                           
</>

);
}

export default Coto;