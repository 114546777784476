import React, { useState, useEffect }  from 'react';
import {Link} from "react-router-dom";
import Umah1 from "../images/umah1.jpg";
import Slider2 from "../images/slider2.jpg";
import { useNavigate} from 'react-router-dom';
import { collection, getDocs, } from "firebase/firestore";
import {db} from '../../firebase'
import Navbar from '../inc/Navbar';
import PaginationOld from './PaginationOld';

import ScrollToTop from "react-scroll-to-top";

function Kitchenwall() {
    const [ setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

    const [showPerPage, setShowPerPage] = useState(50);
    const [pagination, setPagination] =useState({
        start: 0,
        end: showPerPage,
    });

    const onPaginationChange = (start,  end) =>{
        setPagination({start: start, end: end});
    };
    const [searchTerm, setsearchTerm] = useState("");
    const [kajariakitchen, setKajariakitchen] = useState([])
    const navigate = useNavigate()

    const kajariakitchenCollectionRef = collection(db, "kajariakitchen");
    useEffect(() => {

        const getKajariakitchen = async () => {
            const data = await getDocs(kajariakitchenCollectionRef);
            setKajariakitchen(data.docs.map((doc) => ({...doc.data(), id: doc.id })))
        }

        getKajariakitchen()
    }, [])
    return (
        
<>
<Navbar/>
<br/>
<br/>
<div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="carousel">
  <div className="carousel-indicators">
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
   
  </div>
  <div className="carousel-inner">
    <div className="carousel-item active">
      <img src={Umah1} class="d-block w-100" alt="Umahardware"/>
    </div>
    <div className="carousel-item">
      <img src={Slider2} class="d-block w-100" alt="umahardwarenatna"/>
    </div>
  
  </div>
  <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Previous</span>
  </button>
  <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
    <span className="carousel-control-next-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Next</span>
  </button>
</div>


<ScrollToTop smooth top="100" color="dark"/>

<div className="umahardwarebg">
<br/>

<div className="album py-1">
        <div className="container">

            <div className="row">
            <nav aria-label="breadcrumb">
  <ol className="breadcrumb">
  <li className="breadcrumb-item"><Link to="/" href="#">HOME</Link></li>
    <li className="breadcrumb-item"><Link to="/Kajaria" >KAJARIA BEDROOM TILES</Link></li>
    <li className="breadcrumb-item"><Link to="/Johnson" >JOHNSON</Link></li>
    <li className="breadcrumb-item"><Link to="/Orientbell" >ORIENTBELL</Link></li>
    <li className="breadcrumb-item"><Link to="/Jaquar" >JAQUAR</Link></li>
    <li className="breadcrumb-item"><Link to="/Somany" >SOMANY</Link></li>
    <li className="breadcrumb-item"><Link to="/Dulux" >DULUX</Link></li>
    <li className="breadcrumb-item"><Link to="/Coto" >COTO</Link></li>
    <li className="breadcrumb-item"><Link to="/Finolex" >FINOLEX</Link></li>
    <li className="breadcrumb-item active" aria-current="page">KAJARIA KITCHEN WALL TILES</li>
  </ol>
</nav>
</div>
</div>
</div>
<div className="album py-1">
        
            <div className="container">

                <div className="row">
               
                <div className="col-md-6">
                        
                        <div className="card-body">
                        <input type="text" class="form-control" placeholder="Search" name="location" 
                        onChange ={(e) => {
                          setsearchTerm(e.target.value);
                        }}
                        />
                                <div className="d-flex justify-content-between align-items-center">
                                   
                                    
                            

                            </div>
                          
                    
                        </div>

                      
                    </div>
             
                    </div>
                  
                    </div>
                    </div>

<div className="album py-0">
        
        <div className="container">

            <div className="row">
            {kajariakitchen.filter((val)=>{
      if (setsearchTerm === ""){
        return val;
      } else if (
        val.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        val.dis.toLowerCase().includes(searchTerm.toLowerCase())

       
  
      ){
        return val;
      }
    }).slice(pagination.start, pagination.end).map((umahardware) => { return (
       
            <div className="col-md-4 mt-4">
                        <div className="card mb-4 border-White box shadow">
                        <img  className="card-img-top" src={umahardware.img}   alt="umahardware" />
                            <div className="card-body">
                                
                                <h6 style={{color:"Darkblue"}} className="card-text"> <b> { umahardware.title} </b></h6>
<p style={{color:"black"}} className="card-text mb-2">{umahardware.dis}</p>

                         
                                <a href="tel: +91 9932338173"  className="btn btn-outline-dark">  Call Now  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone-forward" viewBox="0 0 16 16">
  <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511zm10.762.135a.5.5 0 0 1 .708 0l2.5 2.5a.5.5 0 0 1 0 .708l-2.5 2.5a.5.5 0 0 1-.708-.708L14.293 4H9.5a.5.5 0 0 1 0-1h4.793l-1.647-1.646a.5.5 0 0 1 0-.708z"/>
</svg>  +91 9932338173</a> 
                                <div className="d-flex justify-content-between align-items-center">
                              
                                        

                                       
                                    
                              
                            </div>
                            </div>
                            </div>
                               
                            </div>
                                )})} 
                            </div>
                            <PaginationOld showPerPage={showPerPage} 
                onPaginationChange={onPaginationChange}
                total={kajariakitchen.length}
                />
                            </div>
                            </div>
                            <br/>
                            </div>
</>

);
}

export default Kitchenwall;