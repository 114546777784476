import React from 'react'
import Umahardwaretec from "../images/umahardwaretec.jpg";
import Umahardware from "../images/UMA.jpg";
import Navbar from "../inc/Navbar";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import ReactPlayer from "react-player";

import Watertecuma from "../images/watertecuma.png";
import Watertecshower from "../images/watertecshower.png";
import Watertecumahardware from "../images/watertec.png";
import Watertecbasin from "../images/watertecbasin.png";
import Umahardwarewatertec from "../images/umahardwarewatertec.png";
import Umahardwareproduct from "../images/umahardwareproduct.png";
import Watertechbath from "../images/watertechbath.png";
import ScrollToTop from "react-scroll-to-top";
function Watertec() {
    const responsive = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 5
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 3
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 2
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1
        }
      };
  return (
    <div>
        <Navbar/>
        <br/>
        <br/>
        <ScrollToTop smooth top="100" color="Darkblue"/>
      <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
  <div className="carousel-indicators">
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
   
  </div>
  <div className="carousel-inner">
    <div className="carousel-item active">
      <img src={Umahardwaretec} className="d-block w-100" alt="Umahardwaretec"/>
    </div>
    <div className="carousel-item">
      <img src={Umahardware} className="d-block w-100" alt="Umahardware"/>
    </div>
   
  </div>
  <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Previous</span>
  </button>
  <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
    <span className="carousel-control-next-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Next</span>
  </button>
</div>
<br/>
<br/>
<div className="album py-2">
        
        <div className="container">

            <div className="row">
           
      <Carousel responsive={responsive}>
      <div className="card border mb-3 me-3" >
  <img className="card-img-top"  src={Watertecuma} alt="Watertecumahardware"/>
  <div className="card-body text">
    <center>
    <h6 className="card-title mb-0"><b>WATERTEC</b></h6>
    <p className="card-text"><small>by Uma Hardware </small></p>
    </center>
  </div>
</div>
  <div className="card border mb-3 me-3" >
  <img className="card-img-top"  src={Watertecshower} alt="Watertecshower"/>
  <div className="card-body text">
    <center>
    <h6 className="card-title mb-0"> <b>WATERTEC</b></h6>
    <p className="card-text "><small>by Uma Hardware</small></p>
    </center>
  </div>
</div>
  <div className="card border mb-3 me-3" >
  <img className="card-img-top"  src={Watertecumahardware} alt="Watertec"/>
  <div className="card-body text">
    <center>
    <h6 className="card-title mb-0"><b>WATERTEC </b></h6>
    <p className="card-text"><small>by Uma Hardware</small></p>
    </center>
  </div>
</div>
<div className="card border mb-3 me-3" >
  <img className="card-img-top"  src={Watertecbasin} alt="Watertecbasin"/>
  <div className="card-body text">
    <center>
    <h6 className="card-title mb-0"><b>WATERTEC </b></h6>
    <p className="card-text"><small>by Uma Hardware</small></p>
    </center>
  </div>
</div>
<div className="card border mb-3 me-3" >
  <img className="card-img-top"  src={Umahardwarewatertec} alt="Umahardwarewatertec"/>
  <div className="card-body text">
    <center>
    <h6 className="card-title mb-0"><b>WATERTEC</b></h6>
    <p className="card-text"><small>by Uma Hardware</small></p>
    </center>
  </div>
</div>
<div className="card border mb-3 me-3" >
  <img className="card-img-top"  src={Watertechbath } alt="Watertechbath "/>
  <div className="card-body text">
    <center>
    <h6 className="card-title mb-0"><b>WATERTEC </b></h6>
    <p className="card-text"><small>by Uma Hardware</small></p>
    </center>
  </div>
</div>
<div className="card border mb-3 me-3" >
  <img className="card-img-top"  src={Umahardwareproduct} alt="Umahardwareproduct"/>
  <div className="card-body text">
    <center>
    <h6 className="card-title mb-0"><b>WATERTEC </b></h6>
    <p className="card-text"><small>by Uma Hardware</small></p>
    </center>
  </div>
</div>
</Carousel>
</div>
</div>

</div>
<br/>
<div className="umahardwarebg">
                            <div className="album py-0">
        
        <div className="container">

            <div className="row">
       
            <div className="col-md-4" >
                     
                        
                          
                                <br/>
                                <div className="card mb-4 me-0 border-white box shadow">
                                <ReactPlayer   width='354px' height='420px' url="https://youtu.be/zraZj00CY2g"/>
                             
                                          </div>
                        
                               
                            </div>
                            <div className="col-md-4" >
                      
                       
                     
                                <br/>
                                <div className="card mb-4 me-0 border-white box shadow">
                                  
                                <ReactPlayer width='354px' height='420px'  url="https://youtu.be/qNkYyEUdweY"/>
                                </div>

                               
                            </div>
                            {/* style={{textAlign: "center"}} */}
                            <div className="col-md-4" >
                        
                                <br/>
                                <div className="card mb-4 me-0 border-white box shadow">
                                <ReactPlayer width='354px' height='420px'  url="https://youtu.be/ig8_HDOsGLA"/>
                                </div>
                               
                            </div>
                            <div className="col-md-4" >
                        
                        <br/>
                        <div className="card mb-4 me-0 border-white box shadow">
                        <ReactPlayer width='354px' height='420px' url="https://youtu.be/fpnScH-rB4g"/>
                     
                       </div>
                    </div>
                    <div className="col-md-4" >
                        
                        <br/>
                        <div className="card mb-4 me-0 border-white box shadow">
                        <ReactPlayer width='354px' height='420px' url="https://youtu.be/CJK_ClT0jmY"/>
                                    </div>
                       
                    </div>
                    <div className="col-md-4" >
                        
                        <br/>
                        <div className="card mb-4 me-0 border-white box shadow">
                        <ReactPlayer  width='354px' height='420px'  url="https://youtu.be/AhliBWtnLsM" />
                         </div>
                       
                    </div>
                    <div className="col-md-4" >
                        
                        <br/>
                        <div className="card mb-4 me-0 border-white box shadow">
                        <ReactPlayer width='354px' height='420px' url="https://youtu.be/AWqN2fxi9ys"/>
                                    </div>
                       
                    </div>
                  
                      <div className="col-md-4" >
                        
                        <br/>
                        <div className="card mb-4 me-0 border-white box shadow">
                        <ReactPlayer width='354px' height='420px' url=" https://youtu.be/pK8vu-5wG2s"/>
                                    </div>
                       
                    </div>
                            </div>
                            
                            </div>
                        </div>
                        </div>
                        
                           
    </div>
  )
}

export default Watertec
