import React from "react";
import Navbar from "../inc/Navbar";
import Somany from "../pages/Somany";
import {Link} from "react-router-dom";
import Slider3 from "../inc/Slider3";
function SomanyPage (){
    return(
        <>
        <Navbar/>
        <br/>
        <br/>
        <Slider3/>
     
<div className="album py-2">
        <div className="container">

            <div className="row">
            <nav aria-label="breadcrumb">
  <ol className="breadcrumb">
  <li className="breadcrumb-item"><Link to="/" href="#">HOME</Link></li>
    <li className="breadcrumb-item"><Link to="/Kajaria" >KAJARIA BEDROOM TILES</Link></li>
    <li className="breadcrumb-item"><Link to="/KajariaKitchenwall" >KAJARIA KITCHEN WALL TILES</Link></li>
    <li className="breadcrumb-item"><Link to="/Johnson" >JOHNSON</Link></li>
    <li className="breadcrumb-item"><Link to="/Orientbell" >ORIENTBELL</Link></li>
    <li className="breadcrumb-item"><Link to="/Jaquar" >JAQUAR</Link></li>
   
    <li className="breadcrumb-item"><Link to="/Dulux" > DULUX</Link></li>
    <li className="breadcrumb-item"><Link to="/Coto" >COTO</Link></li>
    <li className="breadcrumb-item"><Link to="/Finolex" >FINOLEX</Link></li>
    <li className="breadcrumb-item active" aria-current="page">SOMANY PRODUCT</li>
  </ol>
</nav>
</div>
</div>
</div>
     
        <Somany/>
        </>
    );
}

export default SomanyPage;