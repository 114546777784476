import React, { useState, useEffect }  from 'react';
import { useNavigate} from 'react-router-dom';
import { collection, getDocs, } from "firebase/firestore";
import {db} from '../../firebase'

import PaginationOld from './PaginationOld';

import ScrollToTop from "react-scroll-to-top";

function Orientbell() {
    const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

    const [showPerPage, setShowPerPage] = useState(50);
    const [pagination, setPagination] =useState({
        start: 0,
        end: showPerPage,
    });

    const onPaginationChange = (start,  end) =>{
        setPagination({start: start, end: end});
    };
    const [searchTerm, setsearchTerm] = useState("");
    const [orientbell, setOrientbell] = useState([])
    const navigate = useNavigate()

    const orientbellCollectionRef = collection(db, "orientbell");
    useEffect(() => {

        const getOrientbell = async () => {
            const data = await getDocs(orientbellCollectionRef);
            setOrientbell(data.docs.map((doc) => ({...doc.data(), id: doc.id })))
        }

        getOrientbell()
    }, [])
    return (
        
<>



<ScrollToTop smooth top="100" color="dark"/>




<div className="album py-1">
        
            <div className="container">

                <div className="row">
               
                <div className="col-md-6">
                        
                        <div className="card-body">
                        <input type="text" class="form-control" placeholder="Search" name="location" 
                        onChange ={(e) => {
                          setsearchTerm(e.target.value);
                        }}
                        />
                                <div className="d-flex justify-content-between align-items-center">
                                   
                                    
                            

                            </div>
                          
                    
                        </div>

                      
                    </div>
             
                    </div>
                  
                    </div>
                    </div>

<div className="album py-0">
        
        <div className="container">

            <div className="row">
            {orientbell.filter((val)=>{
      if (setsearchTerm === ""){
        return val;
      } else if (
        val.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        val.dis.toLowerCase().includes(searchTerm.toLowerCase()) 
    

       
  
      ){
        return val;
      }
    }).slice(pagination.start, pagination.end).map((umahardware) => { return (
       
            <div className="col-md-4 mt-4">
                        <div className="card mb-4 border- box ">
                        <img  className="card-img-top" src={umahardware.img}   alt="umahardware" />
                            <div className="card-body">
                                <center>
                                <h5 style={{color:"dark"}} className="card-text mb-2"> <b> { umahardware.title} </b></h5>
                                <p style={{color:"Gray"}} className="card-text mb-2"> <b> { umahardware.dis} </b></p>
                                <p style={{color:"Gray"}} className="card-text mb-2"> <b> { umahardware.size} </b></p>
                                </center>


                         

                              
                                <div className="d-flex justify-content-between align-items-center">
                              
                                        

                                       
                                    
                              
                            </div>
                            </div>
                            </div>
                               
                            </div>
                                )})} 
                            </div>
                            <PaginationOld showPerPage={showPerPage} 
                onPaginationChange={onPaginationChange}
                total={orientbell.length}
                />
                            </div>
                            </div>
                            <br/>
                            
                           
</>

);
}

export default Orientbell;