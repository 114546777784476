import React from "react";
import Navbar from "../inc/Navbar";
import Jaquar from "../pages/Jaquar";
import {Link} from "react-router-dom";
function Jaquarm (){
    return(
        <>
        <Navbar/>
        <br/>
        <br/>
        <br/>
        <div className="album py-1">
        <div className="container">

            <div className="row">
            <nav aria-label="breadcrumb">
  <ol className="breadcrumb">
  <li className="breadcrumb-item"><Link to="/" href="#">HOME</Link></li>
    <li className="breadcrumb-item"><Link to="/Kajaria" >KAJARIA BEDROOM TILES</Link></li>
    <li className="breadcrumb-item"><Link to="/KajariaKitchenwall" >KAJARIA KITCHEN WALL TILES</Link></li>
    <li className="breadcrumb-item"><Link to="/Johnson" >JOHNSON</Link></li>
    <li className="breadcrumb-item"><Link to="/Orientbell" >ORIENTBELL</Link></li>
   
    <li className="breadcrumb-item"><Link to="/Somany" >SOMANY</Link></li>
    <li className="breadcrumb-item"><Link to="/Dulux" >DULUX</Link></li>
    <li className="breadcrumb-item"><Link to="/Coto" >COTO</Link></li>
    <li className="breadcrumb-item"><Link to="/Finolex" >FINOLEX</Link></li>
    <li className="breadcrumb-item active" aria-current="page">JAQUAR PRODUCT</li>
  </ol>
</nav>
</div>
</div>
</div>
        <Jaquar/>
        </>
    );
}

export default Jaquarm;