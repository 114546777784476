import React, { useState, useEffect }  from 'react';
import {Link} from "react-router-dom";
import { useNavigate} from 'react-router-dom';
import { collection, getDocs, } from "firebase/firestore";
import {db} from '../../firebase'
import Navbar from '../inc/Navbar';
import PaginationOld from './PaginationOld';

import ScrollToTop from "react-scroll-to-top";

function Dulux() {
    const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

    const [showPerPage, setShowPerPage] = useState(50);
    const [pagination, setPagination] =useState({
        start: 0,
        end: showPerPage,
    });

    const onPaginationChange = (start,  end) =>{
        setPagination({start: start, end: end});
    };
    const [searchTerm, setsearchTerm] = useState("");
    const [dulux, setDulux] = useState([])
    const navigate = useNavigate()

    const duluxCollectionRef = collection(db, "dulux");
    useEffect(() => {

        const getDulux = async () => {
            const data = await getDocs(duluxCollectionRef);
            setDulux(data.docs.map((doc) => ({...doc.data(), id: doc.id })))
        }

        getDulux()
    }, [])
    return (
        
<>
<Navbar/>
<br/>
<br/>

<ScrollToTop smooth top="100" color="Gray"/>


<br/>

<div className="album py-2">
        <div className="container">

            <div className="row">
            <nav aria-label="breadcrumb">
  <ol className="breadcrumb">
  <li className="breadcrumb-item"><Link to="/" href="#">HOME</Link></li>
    <li className="breadcrumb-item"><Link to="/Kajaria" >KAJARIA BEDROOM TILES</Link></li>
    <li className="breadcrumb-item"><Link to="/KajariaKitchenwall" >KAJARIA KITCHEN WALL TILES</Link></li>
    <li className="breadcrumb-item"><Link to="/Johnson" >JOHNSON</Link></li>
    <li className="breadcrumb-item"><Link to="/Orientbell" >ORIENTBELL</Link></li>
    <li className="breadcrumb-item"><Link to="/Jaquar" >JAQUAR</Link></li>
   
    <li className="breadcrumb-item"><Link to="/Somany" >SOMANY</Link></li>
    <li className="breadcrumb-item"><Link to="/Coto" >COTO</Link></li>
    <li className="breadcrumb-item"><Link to="/Finolex" >FINOLEX</Link></li>
    <li className="breadcrumb-item active" aria-current="page">DULUX PRODUCT</li>
  </ol>
</nav>
</div>
</div>
</div>
<div className="album py-1">
        
            <div className="container">

                <div className="row">
               
                <div className="col-md-6">
                        
                        <div className="card-body">
                        <input type="text" class="form-control" placeholder="Search" name="location" 
                        onChange ={(e) => {
                          setsearchTerm(e.target.value);
                        }}
                        />
                                <div className="d-flex justify-content-between align-items-center">
                                   
                                    
                            

                            </div>
                          
                    
                        </div>

                      
                    </div>
             
                    </div>
                  
                    </div>
                    </div>

<div className="album py-0">
        
        <div className="container">

            <div className="row">
            {dulux.filter((val)=>{
      if (setsearchTerm === ""){
        return val;
      } else if (
        val.title.toLowerCase().includes(searchTerm.toLowerCase()) 
    

       
  
      ){
        return val;
      }
    }).slice(pagination.start, pagination.end).map((umahardware) => { return (
       
            <div className="col-md-3 mt-4">
                        <div className="card mb-4 border- box ">
                        <img  className="card-img-top" src={umahardware.img}   alt="dulux" />
                            <div className="card-body">
                             
                                <h6 style={{color:"Gray"}} className="card-text mb-4"> <b> { umahardware.title} </b></h6>
                                <p style={{color:"Gray"}} className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" color="green" fill="currentColor" className="bi bi-check2" viewBox="0 0 16 16">
  <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
</svg> { umahardware.dis1} </p>
                                <p style={{color:"Gray"}} className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" color="green" fill="currentColor" className="bi bi-check2" viewBox="0 0 16 16">
  <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
</svg>  { umahardware.dis2} </p>
                                <p style={{color:"Gray"}} className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" color="green" fill="currentColor" className="bi bi-check2" viewBox="0 0 16 16">
  <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
</svg>  { umahardware.dis3} </p>
                                <p style={{color:"Gray"}} className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-geo-alt" viewBox="0 0 16 16">
  <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z"/>
  <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
</svg>  Available in Uma Hardware</p>
                              


                         
                              
                                <div className="d-flex justify-content-between align-items-center">
                              
                                        

                                       
                                    
                              
                            </div>
                            </div>
                            </div>
                               
                            </div>
                                )})} 
                            </div>
                            <PaginationOld showPerPage={showPerPage} 
                onPaginationChange={onPaginationChange}
                total={dulux.length}
                />
                            </div>
                            </div>
                            <br/>
                            
                           
</>

);
}

export default Dulux;