import React from 'react'
import Navbar from "../inc/Navbar";
import Slider from "../inc/Slider";
import Dulux from "../images/dulux.jpg";
import CatloguePhinolex from "../images/catlogue2.jpg";
import cotobathware from "../images/cotocatlogue.jpg";
import Kajriacatlogue from "../images/kajriacatlogue.jpg";
import Jaqure from "../images/u.jpg";
import Umamap from "../pages/Umamap";
import Jaquar from "../pages/Jaquar";
import Orient from "../pages/Orient";
import Somany from "../pages/Somany";
import Johnson from "../images/johnson.jpg";
import Orientbellcatlogue from "../images/orientbellcatlogue.jpg";
import Somanycatlogue from "../images/somanycatlogue.jpg";
import PdfJaquar from "../images/jaquar.pdf";
import PdfJohnson from "../images/Johnson.pdf";
import Pdffinoloex from "../images/finoloex.pdf";
import PdfCoto from "../images/Coto.pdf";
import SOMANYPDFH from "../images/SOMANYPDF.pdf";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import Pdfvitronitekitchenslab from "../images/vitronite-kitchen-slab.pdf";
import Pdforientbellcatalogue from "../images/orientbellcatalogue.pdf";
import { Typewriter } from 'react-simple-typewriter';
import {Link} from "react-router-dom";
import ReactPlayer from "react-player";

import WALLDESIGNUMAhardware from "../images/WALLDESIGNUMA.jpg";
import WALLDESIGNUMAhardwarenatna from "../images/UMAHARDWAREWALL.jpg";
import UMAHARDWARETEMPLE1 from "../images/UMAHARDWARETEMPLE.jpg";
import UMAHARDWAREDESIGN1 from "../images/UMAHARDWAREDESIGN.jpg";

import Watertecuma from "../images/watertecuma.png";
import Watertecshower from "../images/watertecshower.png";
import Watertec from "../images/watertec.png";
import Watertecbasin from "../images/watertecbasin.png";
import Umahardwarewatertec from "../images/umahardwarewatertec.png";
import Umahardwareproduct from "../images/umahardwareproduct.png";
import Watertechbath from "../images/watertechbath.png";

import Polyfoamp4 from "../images/polyfoam.jpg";
import p4india from "../images/p4.jpg";
import putty from "../images/0001.jpg";
import putty1 from "../images/0002.jpg";
import putty2 from "../images/0003.jpg";
import putty3 from "../images/0004.jpg";


import { 
  FacebookShareButton, WhatsappShareButton, WhatsappIcon,
   FacebookIcon, TwitterShareButton,   EmailShareButton,   EmailIcon,
   LinkedinShareButton,
   LinkedinIcon,
   TwitterIcon,
}  from "react-share";
import "./Home.css";


function Home() {

  const responsive = {
    superLargeDesktop: {
    
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  return (
    <div>
        <Navbar/>
        <br/>
        <br/>
       
              <div style={{  
  backgroundImage: "url(" + "https://i.postimg.cc/Jz1b26Bf/uma.jpg" + ")",
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat'
}}>
     <br/>
     <br/>
     <br/>
    
    
     
  
     <div className="album py-2">
        
        <div className="container">

            <div className="row">
            
              
              
                <h2 style={{color:"white", fontSize: "2rem"}} className="card-text mb-8"> <b>India's No1 Tiles Company</b></h2>
                
               <br/>
               <br/>
               <br/>
               <br/>
               <br/>
     <br/>
     <br/>
            </div>
            </div>
            </div>
<br/>
<br/> 
                 <br/> 
                 <br/> 
                  
                 
                 <br/>
                 <br/>
                 <br/>
              
                 
                 <div className="album py-0">
        
        <div className="container">

            <div className="row">
               
            
                 <p className="card-text" style={{ margin: "auto 0", color: "white"}}> <b>   <span style={{ fontWeight: "bold", marginleft: '10px',color: "white"}}>
    <Typewriter 
    loop
    cursor
    cursorStyle="_"
    typeSpeed={100}
    deleteSpeed={50}
    delaySpeed={1000}
    words={[ "KAJARIA ", "India's No1 Tiles Company", " Available in UMA HARDWARE"]}
    />
</span>
</b></p>

                 </div>
                 
                 </div>
                 </div>
                 <div className="album py-2">
        
        <div className="container">

            <div className="row">
              
                <h2 style={{color:"white", fontSize: "2rem"}} className="card-text "> <b>UMA HARDWARE</b></h2>
               
            </div>
            </div>
            </div>
                <br/>
                <br/>
                 </div>
                

        <Slider/>
        
        <div className="p-2 mb-1 bg-secondary text-white">
        <center><h1>Uma Hardware Natna, Karimpur</h1></center>
          <marquee><h3 style={{pading: "5px"}}><small> স্বাগতম উমা হার্ডওয়ার্ড, নাটনা, করিমপুর, নদীয়া। </small></h3></marquee>
        </div>
        <div className="album py-2">
        <div className="container">

            <div className="row">
       
            <div className="col-md-6" >
            
            <div className="d-flex justify-content-between align-items-center">
                                <div className="card mb-4 me-3 border-dark box">
                                <div className="a2zservice">
                                  <Link to="/Kajaria">
                            <img src={Kajriacatlogue} className="card-img-top"   alt="essco_Catlogue" /></Link>
                            <div class="card-body">
                            <div className="d-flex justify-content-between align-items-center">
                                <p style={{ color: "DarkSlateBlue		"}} className="card-text mb-2"><b>Kajaria</b></p>
                                <Link to="/">
                               
                                </Link>
                                
                                {/* <!-- Button trigger modal --> */}
<button type="button" className="btn btn-dark btn-sm mb-2" data-bs-toggle="modal" data-bs-target="#exampleModal">
Share <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" className="bi bi-share-fill" viewBox="0 0 16 16">
  <path d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5z"/>
</svg>
</button>

{/* <!-- Modal --> */}
<div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">Share Now </h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body">
      < FacebookShareButton url="/https://www.umahardware.in/Kajaria" className="me-2" quote={"Uma Hardware"} hastag={"#WebApp"}
>
    <FacebookIcon size={37} round={true}/>
</FacebookShareButton>

 <  WhatsappShareButton url="/https://www.umahardware.in/Kajaria" className="me-2" quote={"Uma Hardware"} hastag={"#WebApp"}
>
    <WhatsappIcon size={37} round={true}/>
</ WhatsappShareButton>

<  TwitterShareButton url="/https://www.umahardware.in/Kajaria" className="me-2" quote={"Uma Hardware"} hastag={"#WebApp"}
>
    <TwitterIcon size={37} round={true}/>
</ TwitterShareButton>

<  LinkedinShareButton url="/https://www.umahardware.in/Kajaria" className="me-2" quote={"Uma Hardware"} hastag={"#WebApp"}
>

    <  LinkedinIcon size={37} round={true}/>
</ LinkedinShareButton>

<  EmailShareButton url="/https://www.umahardware.in/Kajaria" quote={"Uma Hardware"} hastag={"#WebApp"}
>
    <  EmailIcon size={37} round={true}/>
</ EmailShareButton>
      </div>
      <div className="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
      
      </div>
    </div>
  </div>
</div>
</div>
</div>
                              <center>
    <h5 style={{color: "Gray"}} className="card-title mb-2">UMA HARDWARE</h5>
    <Link to="/Kajaria">
    <button type="button" class="btn btn-outline-primary btn-sm mb-2"><small> ক্লিক করুন</small> </button><br/>
    </Link>
    <a href={Pdfvitronitekitchenslab} className="btn btn-outline-dark btn-sm mb-2" >Download Catalogue</a>
    </center>
                                            </div>
                         
                         
                            </div>
                            <div className="card mb-4 me-2 border-dark box shadow">
                              <Link to="/Johnson">
                            <img src={Johnson} className="card-img-top"   alt="CatloguePhinolex" /></Link>
                            <div class="card-body">
                            <div className="d-flex justify-content-between align-items-center">
                                <p style={{ color: "DarkSlateBlue		"}} className="card-text mb-2">Johnson</p>
                                <Link to="/">
                               
                                </Link>
                                
                                {/* <!-- Button trigger modal --> */}
<button type="button" className="btn btn-dark btn-sm mb-2" data-bs-toggle="modal" data-bs-target="#exampleModal">
<small> Share </small> <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" className="bi bi-share-fill" viewBox="0 0 16 16">
  <path d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5z"/>
</svg>
</button>

{/* <!-- Modal --> */}
<div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel"> Share Now </h5>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body">
      < FacebookShareButton url="/https://www.umahardware.in/Johnson" className="me-2" quote={"Uma Hardware"} hastag={"#WebApp"}
>
    <FacebookIcon size={37} round={true}/>
</FacebookShareButton>

 <  WhatsappShareButton url="/https://www.umahardware.in/Johnson" className="me-2" quote={"Fashion World Ladies Beauty Parlour"} hastag={"#WebApp"}
>
    <WhatsappIcon size={37} round={true}/>
</ WhatsappShareButton>

<  TwitterShareButton url="/https://www.umahardware.in/Johnson" className="me-2" quote={"Fashion World Ladies Beauty Parlour"} hastag={"#WebApp"}
>
    <TwitterIcon size={37} round={true}/>
</ TwitterShareButton>

<  LinkedinShareButton url="/https://www.umahardware.in/Johnson" className="me-2" quote={"Fashion World Ladies Beauty Parlour"} hastag={"#WebApp"}
>

    <  LinkedinIcon size={37} round={true}/>
</ LinkedinShareButton>

<  EmailShareButton url="/https://www.umahardware.in/Johnson" quote={"Fashion World Ladies Beauty Parlour"} hastag={"#WebApp"}
>
    <  EmailIcon size={37} round={true}/>
</ EmailShareButton>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
      
      </div>
    </div>
  </div>
</div>
</div>
</div>
        
                              <center>
    <h5 style={{color: "Gray"}} className="card-title">UMA HARDWARE</h5>
    <Link to="/Johnson">
    <button type="button" class="btn btn-outline-primary btn-sm mb-2"><small> ক্লিক করুন</small> </button><br/>
    </Link>
    <a href={PdfJohnson} className="btn btn-outline-dark btn-sm mb-2" >Download Catalogue</a>
    </center>
                                            </div>
                         
                         
                            </div>
                           
                            <div className="d-flex justify-content-between align-items-center">
                            <div className="card mb-4 me-3 border-dark box shadow">
                              <Link to="/Orientbell">
                            <img src={Orientbellcatlogue} className="card-img-top"   alt="cotobathware_catalogue" /></Link>
                            <div class="card-body">
                            <div className="d-flex justify-content-between align-items-center">
                                <p style={{ color: "DarkSlateBlue		"}} className="card-text mb-2"><small>Orientbell</small></p>
                                <Link to="/">
                               
                                </Link>
                                
                                {/* <!-- Button trigger modal --> */}
<button type="button" className="btn btn-dark btn-sm mb-2" data-bs-toggle="modal" data-bs-target="#exampleModal">
Share <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" className="bi bi-share-fill" viewBox="0 0 16 16">
  <path d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5z"/>
</svg>
</button>

{/* <!-- Modal --> */}
<div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">Share Now </h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body">
      < FacebookShareButton url="/https://www.umahardware.in/Orientbell" className="me-2" quote={"Uma Hardware"} hastag={"#WebApp"}
>
    <FacebookIcon size={37} round={true}/>
</FacebookShareButton>

 <  WhatsappShareButton url="/https://www.umahardware.in/Orientbell" className="me-2" quote={"Uma Hardware"} hastag={"#WebApp"}
>
    <WhatsappIcon size={37} round={true}/>
</ WhatsappShareButton>

<  TwitterShareButton url="/https://www.umahardware.in/Orientbell" className="me-2" quote={"Uma Hardware"} hastag={"#WebApp"}
>
    <TwitterIcon size={37} round={true}/>
</ TwitterShareButton>

<  LinkedinShareButton url="/https://www.umahardware.in/Orientbell" className="me-2" quote={"Uma Hardware"} hastag={"#WebApp"}
>

    <  LinkedinIcon size={37} round={true}/>
</ LinkedinShareButton>

<  EmailShareButton url="/https://www.umahardware.in/Orientbell" quote={"Uma Hardware"} hastag={"#WebApp"}
>
    <  EmailIcon size={37} round={true}/>
</ EmailShareButton>
      </div>
      <div className="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
      
      </div>
    </div>
  </div>
</div>
</div>
</div>
                              <center>
    <h5 style={{color: "Gray"}} className="card-title">UMA HARDWARE</h5>
    <Link to="/Orientbell">
    <button type="button" class="btn btn-outline-primary btn-sm mb-2"><small> ক্লিক করুন</small> </button><br/>
    </Link>
    <a href={Pdforientbellcatalogue} className="btn btn-outline-dark btn-sm mb-2" >Download Catalogue</a>
    </center>
                                            </div>
                         
                         
                            
                            <div className="card mb-4 me-0 border-dark box shadow">
                              <Link to="/coto">
                            <img src={cotobathware} className="card-img-top"   alt="Coto" /></Link>
                            <div class="card-body">
                            <div className="d-flex justify-content-between align-items-center">
                                <p style={{ color: "DarkSlateBlue		"}} className="card-text mb-2"><b>Coto</b></p>
                                <Link to="/">
                               
                                </Link>
                                
                                {/* <!-- Button trigger modal --> */}
<button type="button" className="btn btn-dark btn-sm mb-2" data-bs-toggle="modal" data-bs-target="#exampleModal">
Share <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" className="bi bi-share-fill" viewBox="0 0 16 16">
  <path d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5z"/>
</svg>
</button>

{/* <!-- Modal --> */}
<div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">Share Now </h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body">
      < FacebookShareButton url="/https://www.umahardware.in/Coto" className="me-2" quote={"Uma Hardware"} hastag={"#WebApp"}
>
    <FacebookIcon size={37} round={true}/>
</FacebookShareButton>

 <  WhatsappShareButton url="/https://www.umahardware.in/Coto" className="me-2" quote={"Uma Hardware"} hastag={"#WebApp"}
>
    <WhatsappIcon size={37} round={true}/>
</ WhatsappShareButton>

<  TwitterShareButton url="/https://www.umahardware.in/Coto" className="me-2" quote={"Uma Hardware"} hastag={"#WebApp"}
>
    <TwitterIcon size={37} round={true}/>
</ TwitterShareButton>

<  LinkedinShareButton url="/https://www.umahardware.in/Coto" className="me-2" quote={"Uma Hardware"} hastag={"#WebApp"}
>

    <  LinkedinIcon size={37} round={true}/>
</ LinkedinShareButton>

<  EmailShareButton url="/https://www.umahardware.in/Coto" quote={"Uma Hardware"} hastag={"#WebApp"}
>
    <  EmailIcon size={37} round={true}/>
</ EmailShareButton>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
      
      </div>
    </div>
  </div>
</div>
</div>
</div>
                              <center>
    <h5 style={{color: "Gray"}} className="card-title">UMA HARDWARE</h5>
    <Link to="/coto">
    <button type="button" class="btn btn-outline-primary btn-sm mb-2"><small> ক্লিক করুন</small> </button><br/>
    </Link>
    <a href={PdfCoto} className="btn btn-outline-dark btn-sm mb-2" >Download Catalogue</a>
    </center>
                                            </div>
                                            </div>
                                            </div>



                           
                            <div className="col-md-6">                        
         <div className="d-flex justify-content-between align-items-center">
                                <div className="card mb-4 me-3 border-dark box ">
                              <Link to="/Jaquar">
                            <img src={Jaqure} className="card-img-top"   alt="Jaqure_catlogue" /></Link>
                            <div class="card-body">
                            <div className="d-flex justify-content-between align-items-center">
                                <p style={{ color: "DarkSlateBlue		"}} className="card-text mb-2"><b>Jaqure</b></p>
                                <Link to="/">
                               
                                </Link>
                                
                                {/* <!-- Button trigger modal --> */}
<button type="button" className="btn btn-dark btn-sm mb-2" data-bs-toggle="modal" data-bs-target="#exampleModal">
Share <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" className="bi bi-share-fill" viewBox="0 0 16 16">
  <path d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5z"/>
</svg>
</button>

{/* <!-- Modal --> */}
<div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">Share Now </h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body">
      < FacebookShareButton url="/https://www.umahardware.in/Jaquar" className="me-2" quote={"Uma Hardware"} hastag={"#WebApp"}
>
    <FacebookIcon size={37} round={true}/>
</FacebookShareButton>

 <  WhatsappShareButton url="/https://www.umahardware.in/Jaquar" className="me-2" quote={"Uma Hardware"} hastag={"#WebApp"}
>
    <WhatsappIcon size={37} round={true}/>
</ WhatsappShareButton>

<  TwitterShareButton url="/https://www.umahardware.in/Jaquar" className="me-2" quote={"Uma Hardware"} hastag={"#WebApp"}
>
    <TwitterIcon size={37} round={true}/>
</ TwitterShareButton>

<  LinkedinShareButton url="/https://www.umahardware.in/Jaquar" className="me-2" quote={"Uma Hardware"} hastag={"#WebApp"}
>

    <  LinkedinIcon size={37} round={true}/>
</ LinkedinShareButton>

<  EmailShareButton url="/https://www.umahardware.in/Jaquar" quote={"Uma Hardware"} hastag={"#WebApp"}
>
    <  EmailIcon size={37} round={true}/>
</ EmailShareButton>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
      
      </div>
    </div>
  </div>
</div>
</div>
</div>
                              <center>
    <h5 style={{color: "Gray"}} className="card-title">UMA HARDWARE</h5>
    <Link to="/Jaquar">
    <button type="button" class="btn btn-outline-primary btn-sm mb-2"><small> ক্লিক করুন</small> </button><br/>
    </Link>
    <a href={PdfJaquar} className="btn btn-outline-dark btn-sm mb-2" >Download Catalogue</a>
    </center>
                                            
                                            </div>
                            <div className="card mb-4 border-dark box shadow">
                              <Link to="/Somany">
                            <img src={Somanycatlogue} className="card-img-top"   alt="Somanycatlogue" /></Link>
                            <div class="card-body">
                            <div className="d-flex justify-content-between align-items-center">
                              <Link to="/Somany">
                                <p style={{ color: "DarkSlateBlue		"}} className="card-text mb-2">Somany</p></Link>
                                <Link to="/">
                               
                                </Link>
                                
                                {/* <!-- Button trigger modal --> */}
<button type="button" className="btn btn-dark btn-sm mb-2" data-bs-toggle="modal" data-bs-target="#exampleModal">
Share <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" className="bi bi-share-fill" viewBox="0 0 16 16">
  <path d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5z"/>
</svg>
</button>

{/* <!-- Modal --> */}
<div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">Share Now </h5>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body">
      < FacebookShareButton url="/https://www.umahardware.in/Somany" className="me-2" quote={"Uma Hardware"} hastag={"#WebApp"}
>
    <FacebookIcon size={37} round={true}/>
</FacebookShareButton>

 <  WhatsappShareButton url="/https://www.umahardware.in/Somany" className="me-2" quote={"Uma Hardware"} hastag={"#WebApp"}
>
    <WhatsappIcon size={37} round={true}/>
</ WhatsappShareButton>

<  TwitterShareButton url="/https://www.umahardware.in/Somany" className="me-2" quote={"Uma Hardware"} hastag={"#WebApp"}
>
    <TwitterIcon size={37} round={true}/>
</ TwitterShareButton>

<  LinkedinShareButton url="/https://www.umahardware.in/Somany" className="me-2" quote={"Uma Hardware"} hastag={"#WebApp"}
>

    <  LinkedinIcon size={37} round={true}/>
</ LinkedinShareButton>

<  EmailShareButton url="/https://www.umahardware.in/Somany" quote={"Uma Hardware"} hastag={"#WebApp"}
>
    <  EmailIcon size={37} round={true}/>
</ EmailShareButton>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
      
      </div>
    </div>
  </div>
</div>
</div>
</div>
                              <center>
    <h5 style={{color: "Gray"}} className="card-title">UMA HARDWARE</h5>
    <Link to="/Somany">
    <button type="button" class="btn btn-outline-primary btn-sm mb-2"><small> ক্লিক করুন</small> </button><br/>
    </Link>
    <a href={SOMANYPDFH} className="btn btn-outline-dark btn-sm mb-2" >Download Catalogue</a>
    </center>
                                            </div>
                                            </div>
                            <div className="d-flex justify-content-between align-items-center">
                            <div className="card mb-4 me-3 border-dark box shadow">
                              <Link to="/Dulux">
                                <img src={Dulux} className="card-img-top"   alt="Dulux" /></Link>

                            <div class="card-body">
                            <div className="d-flex justify-content-between align-items-center">
                                <p style={{ color: "DarkSlateBlue		"}} className="card-text mb-2"><b>Dulux</b></p>
                                <Link to="/">
                               
                                </Link>
                                
                                {/* <!-- Button trigger modal --> */}
<button type="button" className="btn btn-dark btn-sm mb-2" data-bs-toggle="modal" data-bs-target="#exampleModal">
Share <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" className="bi bi-share-fill" viewBox="0 0 16 16">
  <path d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5z"/>
</svg>
</button>

{/* <!-- Modal --> */}
<div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">Share Now </h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body">
      < FacebookShareButton url="/https://www.umahardware.in/Dulux" className="me-2" quote={"Uma Hardware"} hastag={"#WebApp"}
>
    <FacebookIcon size={37} round={true}/>
</FacebookShareButton>

 <  WhatsappShareButton url="/https://www.umahardware.in/Dulux" className="me-2" quote={"Uma Hardware"} hastag={"#WebApp"}
>
    <WhatsappIcon size={37} round={true}/>
</ WhatsappShareButton>

<  TwitterShareButton url="/https://www.umahardware.in/Dulux" className="me-2" quote={"Uma Hardware"} hastag={"#WebApp"}
>
    <TwitterIcon size={37} round={true}/>
</ TwitterShareButton>

<  LinkedinShareButton url="/https://www.umahardware.in/Dulux" className="me-2" quote={"Uma Hardware"} hastag={"#WebApp"}
>

    <  LinkedinIcon size={37} round={true}/>
</ LinkedinShareButton>

<  EmailShareButton url="/https://www.umahardware.in/Dulux" quote={"Uma Hardware"} hastag={"#WebApp"}
>
    <  EmailIcon size={37} round={true}/>
</ EmailShareButton>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
      
      </div>
    </div>
  </div>
</div>
</div>
</div>
                              <center>
    <h5 style={{color: "Gray"}} className="card-title">UMA HARDWARE</h5>
    <Link to="/Dulux">
    <button type="button" class="btn btn-outline-primary btn-sm mb-2"><small> ক্লিক করুন</small> </button><br/>
    </Link>
    {/* <a href={Pdfe} className="btn btn-outline-dark btn-sm mb-2" >Download Catalogue</a> */}
    <button className="btn btn-dark btn-sm mb-2">Watch Video</button>
    </center>
                                            </div>
                            <div className="card mb-4 me-0 border-dark box shadow">
                              <Link to="/Finolex">
                            <img src={CatloguePhinolex} className="card-img-top"   alt="Finolex" /></Link>
                            <div class="card-body">
                            <div className="d-flex justify-content-between align-items-center">
                                <p style={{ color: "DarkSlateBlue		"}} className="card-text mb-2">Finolex</p>
                                <Link to="/">
                               
                                </Link>
                                
                                {/* <!-- Button trigger modal --> */}
<button type="button" className="btn btn-dark btn-sm mb-2" data-bs-toggle="modal" data-bs-target="#exampleModal">
Share <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" className="bi bi-share-fill" viewBox="0 0 16 16">
  <path d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5z"/>
</svg>
</button>

{/* <!-- Modal --> */}
<div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">Share Now </h5>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body">
      < FacebookShareButton url="/https://www.umahardware.in/Finolex" className="me-2" quote={"Uma Hardware"} hastag={"#WebApp"}
>
    <FacebookIcon size={37} round={true}/>
</FacebookShareButton>

 <  WhatsappShareButton url="/https://www.umahardware.in/Finolex" className="me-2" quote={"Uma Hardware"} hastag={"#WebApp"}
>
    <WhatsappIcon size={37} round={true}/>
</ WhatsappShareButton>

<  TwitterShareButton url="/https://www.umahardware.in/Finolex" className="me-2" quote={"Uma Hardware"} hastag={"#WebApp"}
>
    <TwitterIcon size={37} round={true}/>
</ TwitterShareButton>

<  LinkedinShareButton url="/https://www.umahardware.in/Finolex" className="me-2" quote={"Uma Hardware"} hastag={"#WebApp"}
>

    <  LinkedinIcon size={37} round={true}/>
</ LinkedinShareButton>

<  EmailShareButton url="/https://www.umahardware.in/Finolex" quote={"Uma Hardware"} hastag={"#WebApp"}
>
    <  EmailIcon size={37} round={true}/>
</ EmailShareButton>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
      
      </div>
    </div>
  </div>
</div>
</div>
</div>
                              <center>
    <h5 style={{color: "Gray"}} className="card-title">UMA HARDWARE</h5>
    <Link to="/Finolex">
    <button type="button" class="btn btn-outline-primary btn-sm mb-2"><small> ক্লিক করুন</small> </button><br/>
    </Link>
    <a href={Pdffinoloex} className="btn btn-outline-dark btn-sm mb-2" >Download Catalogue</a>
    </center>
                                            </div>
                                            </div>

                                
                            
                                     
                              
                                   
                             <div className="d-flex justify-content-between align-items-center">
                           
                     
                         </div>
                            
                         </div>
                
                         </div>
                            </div>
                            </div>
                            <div className="p-3 mb-2 bg-secondary text-white">
                            <div className="album py-1">
        
        <div className="container">

            <div className="row">
              <h1><b>WATERTEC PRODUCTS</b></h1>
              </div>
              </div>
              </div>
                            </div>

                            <div className="album py-2">
        
        <div className="container">

            <div className="row">
           
      <Carousel responsive={responsive}>
      <div className="card border mb-3 me-3" >
  <img className="card-img-top"  src={Watertecuma} alt="Watertecuma"/>
  <div className="card-body text">
    <center>
    <h6 className="card-title mb-0"><b>WATERTEC</b></h6>
    <p className="card-text"><small>by Uma Hardware </small></p>
    </center>
  </div>
</div>
  <div className="card border mb-3 me-3" >
  <img className="card-img-top"  src={Watertecshower} alt="Watertecshower"/>
  <div className="card-body text">
    <center>
    <h6 className="card-title mb-0"> <b>WATERTEC</b></h6>
    <p className="card-text "><small>by Uma Hardware</small></p>
    </center>
  </div>
</div>
  <div className="card border mb-3 me-3" >
  <img className="card-img-top"  src={Watertec} alt="Watertec"/>
  <div className="card-body text">
    <center>
    <h6 className="card-title mb-0"><b>WATERTEC </b></h6>
    <p className="card-text"><small>by Uma Hardware</small></p>
    </center>
  </div>
</div>
<div className="card border mb-3 me-3" >
  <img className="card-img-top"  src={Watertecbasin} alt="Watertecbasin"/>
  <div className="card-body text">
    <center>
    <h6 className="card-title mb-0"><b>WATERTEC </b></h6>
    <p className="card-text"><small>by Uma Hardware</small></p>
    </center>
  </div>
</div>
<div className="card border mb-3 me-3" >
  <img className="card-img-top"  src={Umahardwarewatertec} alt="Umahardwarewatertec"/>
  <div className="card-body text">
    <center>
    <h6 className="card-title mb-0"><b>WATERTEC</b></h6>
    <p className="card-text"><small>by Uma Hardware</small></p>
    </center>
  </div>
</div>
<div className="card border mb-3 me-3" >
  <img className="card-img-top"  src={Watertechbath} alt="Watertechbath "/>
  <div className="card-body text">
    <center>
    <h6 className="card-title mb-0"><b>WATERTEC </b></h6>
    <p className="card-text"><small>by Uma Hardware</small></p>
    </center>
  </div>
</div>
<div className="card border mb-3 me-3" >
  <img className="card-img-top"  src={Umahardwareproduct} alt="Umahardwareproduct"/>
  <div className="card-body text">
    <center>
    <h6 className="card-title mb-0"><b>WATERTEC </b></h6>
    <p className="card-text"><small>by Uma Hardware</small></p>
    </center>
  </div>
</div>
</Carousel>
</div>
</div>

</div>
<br/>
                              <div className="album py-0">
        
        <div className="container">
                <h4 style={{color:"Gray"}}>UMA HARDWARE Papular Product</h4>
            <div className="row">
              </div>
              </div>
            </div>

                            <div className="album py-2">
        
        <div className="container">

            <div className="row">
           
      <Carousel responsive={responsive}>
      <div className="card border mb-3 me-3" >
  <img className="card-img-top"  src={WALLDESIGNUMAhardware} alt="Walltiles"/>
  <div className="card-body text">
    <h6 className="card-title mb-0"><b>Wall Art Tiles Design</b></h6>
    <p className="card-text"><small>by Uma Hardware </small></p>

  </div>
</div>
  <div className="card border mb-3 me-3" >
  <img className="card-img-top"  src={WALLDESIGNUMAhardwarenatna} alt="product"/>
  <div className="card-body text">
    <h6 className="card-title mb-0"> <b>Somany Wall Tiles</b></h6>
    <p className="card-text "><small>by Uma Hardware</small></p>

  </div>
</div>
  <div className="card border mb-3 me-3" >
  <img className="card-img-top"  src={UMAHARDWARETEMPLE1} alt="product"/>
  <div className="card-body text">
    <h6 className="card-title mb-0"><b>Marble Temple </b></h6>
    <p className="card-text"><small>by Uma Hardware</small></p>

  </div>
</div>
<div className="card border mb-3 me-3" >
  <img className="card-img-top"  src={UMAHARDWAREDESIGN1} alt="Build"/>
  <div className="card-body text">
    <h6 className="card-title mb-0"><b>Somany Wall Tiles </b></h6>
    <p className="card-text"><small>by Uma Hardware</small></p>

  </div>
</div>
</Carousel>
</div>
</div>

</div>
<br/>


<div className="album py-2">
        
        <div className="container">

            <div className="row">
           
      <Carousel responsive={responsive}>
      <div className="card border mb-3 me-3" >
  <img className="card-img-top"  src={Polyfoamp4} alt="Watertecuma"/>
  <div className="card-body text">
    <center>
    <h6 className="card-title mb-0"><b>POLYEXPERT, POLYFOAM, POLYXON</b></h6>
    <p className="card-text"><small>by Uma Hardware </small></p>
    </center>
  </div>
</div>
  <div className="card border mb-3 me-3" >
  <img className="card-img-top"  src={p4india} alt="p4india"/>
  <div className="card-body text">
    <center>
    <h6 className="card-title mb-0"> <b>COPPER</b></h6>
    <p className="card-text "><small>by Uma Hardware</small></p>
    </center>
  </div>
</div>
  <div className="card border mb-3 me-3" >
  <img className="card-img-top"  src={putty} alt="putty"/>
  <div className="card-body text">
    <center>
    <h6 className="card-title mb-0"><b>PUTTY </b></h6>
    <p className="card-text"><small>by Uma Hardware</small></p>
    </center>
  </div>
</div>
<div className="card border mb-3 me-3" >
  <img className="card-img-top"  src={putty1} alt="putty1"/>
  <div className="card-body text">
    <center>
    <h6 className="card-title mb-0"><b>PUTTY</b></h6>
    <p className="card-text"><small>by Uma Hardware</small></p>
    </center>
  </div>
</div>
<div className="card border mb-3 me-3" >
  <img className="card-img-top"  src={putty2} alt="Umahardwareputty"/>
  <div className="card-body text">
    <center>
    <h6 className="card-title mb-0"><b>PUTTY</b></h6>
    <p className="card-text"><small>by Uma Hardware</small></p>
    </center>
  </div>
</div>
<div className="card border mb-3 me-3" >
  <img className="card-img-top"  src={putty3} alt="Umahardwareputty "/>
  <div className="card-body text">
    <center>
    <h6 className="card-title mb-0"><b>PUTTY </b></h6>
    <p className="card-text"><small>by Uma Hardware</small></p>
    </center>
  </div>
</div>

</Carousel>
</div>
</div>

</div>
<br/>

                 <div className="umahardwarebg">
                            <div className="album py-0">
        
        <div className="container">

            <div className="row">
       
            <div className="col-md-4" >
                     
                        
                          
                                <br/>
                                <div className="card mb-4 me-0 border-white box shadow">
                                <ReactPlayer   width='354px' height='420px' url="https://www.youtube.com/watch?v=r9PpqkeJ3Vs"/>
                             
                                          </div>
                        
                               
                            </div>
                            <div className="col-md-4" >
                      
                       
                     
                                <br/>
                                <div className="card mb-4 me-0 border-white box shadow">
                                  
                                <ReactPlayer width='354px' height='420px'  url="https://www.youtube.com/watch?v=a_HhuQ_p9XE"/>
                                </div>
{/* <div className="newa">
<button onClick={likef}  className={[likeactive ? 'active-like' :null,'button'].join('')}>Like{like}</button>
<button onClick={dislikef} className={[dislikeactive? "active-dislike" :null,"button"].join("")}>Dislike {dislike}</button>
</div> */}
                               
                            </div>
                            {/* style={{textAlign: "center"}} */}
                            <div className="col-md-4" >
                        
                                <br/>
                                <div className="card mb-4 me-0 border-white box shadow">
                                <ReactPlayer width='354px' height='420px'  url="https://www.youtube.com/watch?v=UOs1m6a0-no&t=20s"/>
                                </div>
                               
                            </div>
                            <div className="col-md-4" >
                        
                        <br/>
                        <div className="card mb-4 me-0 border-white box shadow">
                        <ReactPlayer width='354px' height='420px' url="https://www.youtube.com/watch?v=YDrsD-hvi8U"/>
                     
                       </div>
                    </div>
                    <div className="col-md-4" >
                        
                        <br/>
                        <div className="card mb-4 me-0 border-white box shadow">
                        <ReactPlayer width='354px' height='420px' url="https://www.youtube.com/watch?time_continue=19&v=7fH6StdFe10"/>
                                    </div>
                       
                    </div>
                    <div className="col-md-4" >
                        
                        <br/>
                        <div className="card mb-4 me-0 border-white box shadow">
                        <ReactPlayer  width='354px' height='420px'  url="https://www.youtube.com/watch?v=5aM2a422O80" />
                         </div>
                       
                    </div>
                    <div className="col-md-4" >
                        
                        <br/>
                        <div className="card mb-4 me-0 border-white box shadow">
                        <ReactPlayer width='354px' height='420px' url="https://www.youtube.com/watch?v=F3DFQhmIyvw"/>
                                    </div>
                       
                    </div>
                    <div className="col-md-4" >
                        
                        <br/>
                        <div className="card mb-4 me-0 border-white box shadow">
                        <ReactPlayer width='354px' height='420px' url="https://www.youtube.com/watch?v=ih47lsABBJ0"/>
                                    </div>
                       
                    </div>
                    <div className="col-md-4" >
                        
                        <br/>
                        <div className="card mb-4 me-0 border-white box shadow">
                        <ReactPlayer width='354px' height='420px' url="https://www.youtube.com/watch?v=WJIhCpeqeM4"/>
                                    </div>
                                   
                    </div>



                    <div className="col-md-4" >
                        
                        <br/>
                        <div className="card mb-4 me-0 border-white box shadow">
                        <ReactPlayer width='354px' height='420px' url="https://www.youtube.com/watch?v=y9qBbseisbA&t=4s"/>
                                    </div>
                                   
                    </div>
                    <div className="col-md-4" >
                        
                        <br/>
                        <div className="card mb-4 me-0 border-white box shadow">
                        <ReactPlayer width='354px' height='420px' url="https://www.youtube.com/watch?v=bPpJGYmJM4E&t=42s"/>
                                    </div>
                                   
                    </div>
                    <div className="col-md-4" >
                        
                        <br/>
                        <div className="card mb-4 me-0 border-white box shadow">
                        <ReactPlayer width='354px' height='420px' url="https://www.youtube.com/watch?v=YJNCiIRZjzs&t=13s"/>
                                    </div>
                                   
                    </div>
                    <div className="col-md-4" >
                        
                        <br/>
                        <div className="card mb-4 me-0 border-white box shadow">
                        <ReactPlayer width='354px' height='420px' url=" https://www.youtube.com/watch?v=JnnR3QVlEqs&t=5s"/>
                                    </div>
                                   
                    </div>
                   
                      <div className="col-md-4" >
                        
                        <br/>
                        <div className="card mb-4 me-0 border-white box shadow">
                        <ReactPlayer width='354px' height='420px' url=" https://youtu.be/pK8vu-5wG2s"/>
                                    </div>
                       
                    </div>
                            </div>
                            
                            </div>
                            </div>
                            <br/>
                            <hr/>
                            </div>
                            <Orient/>
                            
                            <hr/>
                            <Somany/>
                            <hr/>
                            <Jaquar/>
                          
                           <Umamap/>
                         
    </div>
  )
}

export default Home
