
import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';
import "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import { getStorage } from 'firebase/storage'
import "firebase/storage";



const firebaseConfig = {
  apiKey: "AIzaSyCKAEU_W8IUmYQQ1_9zxXVmmRYCKW_V2k0",
  authDomain: "uma-hardware.firebaseapp.com",
  projectId: "uma-hardware",
  storageBucket: "uma-hardware.appspot.com",
  messagingSenderId: "558646434777",
  appId: "1:558646434777:web:c9001cc0f6c5892a30d7c8",
  measurementId: "G-TDPLYEQFRX"
 
};
//init firebase app
const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
export const auth = getAuth(app);
export const db = getFirestore(app);




export default app;

//google-site-verification=W8m9JBGrIVZDQNBx__rDzBStbkhVdJ_1KUKD7tXamDk