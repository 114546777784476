import React from 'react'
import UmaImage from "../images/JAQUARhome.jpg";
import Umahardware from "../images/UMAH.jpg";
import Tileskarimpur from "../images/umahardwarenatna.jpg";
import Umahardware1 from "../images/umahard.jpg";
import Umatiles from "../images/UMA.jpg";
function Slider() {
  return (
    <div>
      <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
  <div className="carousel-inner">
    <div className="carousel-item active">
      <img src={UmaImage} className="d-block w-100" alt="..."/>
    </div>
    <div className="carousel-item">
      <img src={Umahardware} className="d-block w-100" alt="Umahardware"/>
    </div>
    <div className="carousel-item">
      <img src={Tileskarimpur} className="d-block w-100" alt="Tileskarimpur"/>
    </div>
    <div className="carousel-item">
      <img src={Umahardware1} className="d-block w-100" alt="Umahardware1"/>
    </div>
    <div className="carousel-item">
      <img src={Umatiles} className="d-block w-100" alt="Umatiles"/>
    </div>
  </div>
  <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Previous</span>
  </button>
  <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
    <span className="carousel-control-next-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Next</span>
  </button>
</div>
    </div>
  )
}

export default Slider
