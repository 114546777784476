import React from 'react';

function Umamap() {
    return (
<>
<div className="album py-4">
        
        <div className="container">

            <div className="row">

    <iframe  width="520" height="400" className="iframe-fluid" id="gmap_canvas" src="https://maps.google.com/maps?width=520&amp;height=400&amp;hl=en&amp;q=Uma%20Hardware,%20XJPX+64V,%20More,%20Natna,%20West%20Bengal%20741122%20Natna+(Uma%20Hardware)&amp;t=&amp;z=12&amp;ie=UTF8&amp;iwloc=B&amp;output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"> </iframe>

</div>

</div>
</div>


</>
);
}

export default Umamap;