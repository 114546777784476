import './App.css';
import {BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from "./components/pages/Home";
import Contact from "./components/pages/Contact";
import Kajaria from "./components/pages/Kajaria";
import Dulux from "./components/pages/Dulux";
import Jaquarm from "./components/pages/Jaquarm";
import Coto from "./components/pages/Coto";
import SomanyPage from "./components/pages/SomanyPage";
import Orientbell from "./components/pages/Orientbell";
import Pinolex from "./components/pages/Pinolex";
import Watertec from "./components/pages/Watertec";

import Johnson from "./components/pages/Johnson";
import KajariaKitchenwall from "./components/pages/KajariaKitchenwall";
import Footer from "./components/inc/Footer";

function App() {
  return (
    
    <Router>
      
  
     
        <div>
      
         
          
          <Routes>
     
         
          <Route axact path="/" element={<Home/>}/>
          <Route axact path="/Kajaria" element={<Kajaria/>}/>
      
          <Route axact path="/coto" element={<Coto/>}/>
          <Route axact path="/Jaquar" element={<Jaquarm/>}/>
          <Route axact path="/Somany" element={<SomanyPage/>}/>
          <Route axact path="/Dulux" element={<Dulux/>}/>
          <Route axact path="/Watertec" element={<Watertec/>}/>
        
          <Route axact path="/Johnson" element={<Johnson/>}/>
          <Route axact path="/Finolex" element={<Pinolex/>}/>
          <Route axact path="/Orientbell" element={<Orientbell/>}/>
          <Route axact path="/KajariaKitchenwall" element={<KajariaKitchenwall/>}/>
          <Route axact path="/Contact" element={<Contact/>}/>
          
          </Routes>
    <Footer/>
  
    </div>

    </Router>
   
       );
}

export default App;
