import React from 'react'
import {Link} from "react-router-dom";
function Navbar() {
  return (
    <div>
        <div className="fixed-top">
       

      <nav className="navbar navbar-expand-lg navbar-dark bg-dark shadow">
  <div className="container-fluid">
    <Link to="/" style={{color:"Darklight"}} className="navbar-brand"> <b>UMA HARDWARE</b></Link>
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse" id="navbarSupportedContent">
      <ul className="navbar-nav me-auto mb-2 mb-lg-0">
        <li className="nav-item">
          <Link to="/" className="nav-link active" aria-current="page">Home</Link>
        </li>
        <li className="nav-item">
          <Link to="/Kajaria" className="nav-link " >Kajaria</Link>
        </li>
        <li className="nav-item">
          <Link to="/Johnson" className="nav-link" >Johnson</Link>
        </li>
        <li className="nav-item">
          <Link to="/Orientbell" className="nav-link" >Orientbell</Link>
        </li>
        <li className="nav-item">
          <Link to="/Somany" className="nav-link" >Somany</Link>
        </li>
        <li className="nav-item">
          <Link to="/Jaquar" className="nav-link" >Jaquar</Link>
        </li>
        <li className="nav-item">
          <Link to="/Dulux" className="nav-link" >Dulux</Link>
        </li>
        <li className="nav-item">
          <Link to="/Coto" className="nav-link" >Coto</Link>
        </li>
        <li className="nav-item">
          <Link to="/Finolex" className="nav-link" >Finolex</Link>
        </li>
        <li className="nav-item">
          <Link to="/Watertec" className="nav-link" >Watertec</Link>
        </li>
       
        <li className="nav-item">
          <Link to="/Contact" className="nav-link" >Contact</Link>
        </li>
      </ul>
      <form className="d-flex">
       <a className="btn btn-outline-light me-2" href="tel: +91 9932338173"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone-plus" viewBox="0 0 16 16">
  <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"/>
  <path fill-rule="evenodd" d="M12.5 1a.5.5 0 0 1 .5.5V3h1.5a.5.5 0 0 1 0 1H13v1.5a.5.5 0 0 1-1 0V4h-1.5a.5.5 0 0 1 0-1H12V1.5a.5.5 0 0 1 .5-.5z"/>
</svg> +91 9932 33 8173</a>
     
      </form>
    </div>
  </div>
</nav>
</div>
    </div>
  )
}

export default Navbar
